.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Vi';
  src:  url('./assets/fonts/Vi-Regular.otf') format('opentype')
  ;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Vi';
  src: local('Vi-Regular.otf'),url('./assets/fonts/Vi-Medium.otf') format('opentype')
    
  ;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vi';
  src:local('Vi-Regular.otf'), url('./assets/fonts/Vi-Heavy.otf') format('opentype')
   
  ;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vi';
  src: local('Vi-Regular.otf'),url('./assets/fonts/Vi-Bold.otf') format('opentype')
   
  ;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vi';
  src: local('Vi-Regular.otf'),url('./assets/fonts/Vi-MediumItalic.otf') format('opentype')
   
  ;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vi';
  src:local('Vi-Regular.otf'), url('./assets/fonts/Vi-RegularItalic.otf') format('opentype')
   
  ;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

/* :root {
  --yellow: #ffc609;
  --green-primary: #09ffc4;
  --sky-primary: #43f2ff;
  --red: #f92c2c;
  /* blue 
  --darkblue: #070b28;   
  --gray: #f5f4f3;
  --light: #fff8f0;
  --color_grey: #b4b6c4;
  --dark-grey: #82878d;
  --border-color:#dfe4e9;
  --green:#32CD32;
  --dark-green:#228B22;
  --white:#FFFFFF;
  --silver:#D3D3D3;
  --blue:#1B5CA7;
  --orange:#F44336;
  --light-silver: #E8E9EB;
  --border-color2: #707070;
  --light-orange: #FF8C00;
  --dark-red: #FF0000;
  --processing:#FF9544;
  --succeeded:#36DE4C;
  --failed:#FF4646;
  --color-input-text: #70717A;
  --color-input-text-border: #D5D7E3;  
  --icon:#FF0000;
  
} */



:root {
  --white: "#0b461e";
  --redx-black: "#111662";
  --darkblue: '#111622';
  --yellow: "#FEDB00";
  --textColor: '#ffffff';
  --red: "#EE2737";
  --contestDetail: "#2F3043";
  --borderColor: '#82838E';
  --heading: 'radial-gradient(30315.23% 252.57% at 179.25% 26.73%, rgba(47, 48, 67, 0.00) 0%, rgba(30, 31, 47, 0.65) 57.88%, rgba(47, 48, 67, 0.00) 100%)';
  --green: '#00954E';
  --completed: '#D5D6D9';
  --headerText:'#fff',
  --blackWhite:'#000'
}